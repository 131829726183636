import React from 'react';
import styled from 'styled-components';

import { Header } from 'components/header/Header';
import { HeaderLink } from 'components/header/HeaderLink';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';

import { Work } from 'components/work/Work';
import { WorkBlock } from 'components/work/WorkBlock';
import { WorkBlockImage } from 'components/work/WorkBlockImage';
import { WorkBlockTextLeft } from 'components/work/WorkBlockTextLeft';
import { SimpleFooter } from 'components/footer/SimpleFooter';

import Img from 'gatsby-image';

import { graphql } from 'gatsby';

import Helmet from 'react-helmet';

import WorkVideoStereosunClip from '../../assets/video/work_stereosun.mp4';
import WorkVideoStereosunPath from '../../assets/images/work_stereosun.jpg';
import WorkVideoStereosun from '../../assets/video/stereosun_full.mp4';

import WorkVideoBrightMP4 from '../../assets/video/work_bright.mp4';
import WorkVideoBrightPath from '../../assets/images/work_bright.jpg';

import WorkVideoFabulatorijClip from '../../assets/video/fabulatorij.mp4';
import WorkVideoFabulatorijPath from '../../assets/images/work_Fabulatorij.jpg';

import WorkVideoMartinClip from '../../assets/video/martin.mp4';
import WorkVideoMartinPath from '../../assets/images/work_Martin.jpg';

import WorkVideoAR9Clip from '../../assets/video/AR9.mp4';
import WorkVideoAR9Path from '../../assets/images/work_AR9.jpg';

import WorkVideoMinoritiClip from '../../assets/video/work_minoriti_loop.mp4';
import WorkVideoMinoritiPath from '../../assets/images/work_Martin.jpg';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import VideoPlayer from 'components/video/VideoPlayer';
import WorkFilter from 'components/work/WorkFilter';

const Wrapper = styled.div`
  padding: 0px;
`;

const StrongText = styled.p`
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 15px;
  display: none;
`;

const AwardTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 500;
`;

const AwardText = styled.p`
  margin-bottom: 5px;
  font-weight: 400;
  opacity: 0.5;
`;

const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
  padding-bottom: 20px;
`;

export default ({ data }) => {
  const { t } = useTranslation();

  const playerRef = React.useRef<HTMLVideoElement>(null);
  const [fullscreen, setFullscreen] = React.useState(false);
  const [controls, setControls] = React.useState(true);

  const options = {
    preload: 'auto',
    controls: true,
    fullscreen: {
      options: {
        navigationUI: 'hide',
      },
    },
    userActions: {
      doubleClick: false,
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('useractive', () => {
      setControls(true);
    });

    player.on('userinactive', () => {
      setControls(false);
    });

    player.on('ended', () => {
      setFullscreen(false);
      playerRef?.current?.currentTime(0);
    });

    player.on('fullscreenchange', () => {
      if (!player.isFullscreen()) {
        handleStop();
      }
    });
  };

  const handlePlay = (source) => {
    // @ts-ignore
    playerRef?.current?.src({ src: source, type: 'video/mp4' });
    setFullscreen(true);
    playerRef?.current?.play();
  };

  const handleStop = () => {
    setFullscreen(false);
    playerRef?.current?.pause();
  };

  return (
    <>
      <Header>
        <HeaderLink name={t('work')} href="/work" className="text-black" />
        <HeaderLink name={t('meet')} href="/contact" className="text-black" />
      </Header>
      <Helmet title="Work" />

      <Container>
        <Wrapper>
          <Row>
            <Column md={12} lg={12} sm={12}>
              <SectionTitle>{t('workTitle')}</SectionTitle>
              <WorkFilter />
            </Column>
          </Row>
        </Wrapper>
      </Container>

      <Work>
      <WorkBlock>
          <WorkBlockTextLeft
            title="Urbana"
            year="2023"
            services="User Research, UX/UI Design, Motion"
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <a href="https://apps.apple.com/us/app/urbana/id1608543324" target="_blank">
              <Img
                loading="eager"
                fluid={data.workImageUrbana.childImageSharp.fluid}
                alt="Urbana"
              />
            </a>
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Fabulatorij"
            year="2021"
            services="Branding, UX/UI Design, Development"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>CSSDesignAwards</AwardTitle>,
              <AwardText>Site of the day</AwardText>,
              <AwardTitle>Awwwards</AwardTitle>,
              <AwardText>Honorable Mention</AwardText>,
            ]}
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoFabulatorijClip}
              onClick={() => window.open('https://www.fabulatorij.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Joan"
            year="2021"
            services="UX/UI Design"
            //extras=""
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <a href="https://getjoan.com" target="_blank">
              <Img loading="eager" fluid={data.workImageJoan.childImageSharp.fluid} alt="Joan" />
            </a>
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="CargoX"
            year="2023"
            services="UX/UI Design, Development"
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <a href="https://cargox.io" target="_blank">
              <Img
                loading="eager"
                fluid={data.workImageCargoX.childImageSharp.fluid}
                alt="CargoX"
              />
            </a>
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Stereosun"
            year="2020"
            services="Concept, UX/UI Design, iOS Development"
            extras="Collaboration with Matija Kocbek"
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              id="videoStereosun"
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              onClick={() => handlePlay(WorkVideoStereosun)}
              poster={WorkVideoStereosunPath}
              className="w-full cursor-pointer"
            >
              <source src={WorkVideoStereosunClip} type="video/mp4" />
            </video>
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="ArtRebel9"
            year="2022"
            services="UX/UI Design, Development"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Awwwards</AwardTitle>,
              <AwardText>Honorable Mention</AwardText>,
            ]}
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoAR9Clip}
              onClick={() => window.open('https://www.artrebel9.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Martin Smerdel"
            year="2022"
            services="UX/UI Design, Development"
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoMartinClip}
              onClick={() => window.open('https://www.martinsmerdel.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Radio Center"
            year="2020"
            services="UX/UI Design, Development"
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <a href="https://radiocenter.si" target="_blank">
              <Img
                loading="eager"
                fluid={data.workImageHRC.childImageSharp.fluid}
                alt="Radio Center"
              />
            </a>
          </WorkBlockImage>
        </WorkBlock>

        <WorkBlock>
          <WorkBlockTextLeft
            title="Bright"
            year="2020"
            services="UX/UI Design, Development"
            extras="With Bright Visuals and Dragan Petoš"
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBrightMP4}
              onClick={() => window.open('https://www.brightvisuals.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>

        <WorkBlock>
          <WorkBlockTextLeft
            title="Cultural district Minoriti"
            year="2022"
            services="UX/UI Design, Development"
            extras=""
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoMinoritiClip}
              onClick={() => window.open('https://www.minoriti.si', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
      </Work>
      <Container>
        <Wrapper>
          <Row>
            <Column sm={12}>
              <SimpleFooter />
            </Column>
          </Row>
        </Wrapper>
      </Container>
      {/* fullscreen Video */}
      <div
        className={
          !fullscreen
            ? 'opacity-0 invisible transition-opacity fixed'
            : 'opacity-100 visible z-10 w-full h-screen top-0 left-0 transition-opacity fixed'
        }
      >
        <VideoPlayer options={options} onReady={handlePlayerReady} />
        <button
          className={`${
            !controls ? 'opacity-0 delay-500' : 'opacity-100'
          } transition-opacity duration-700  absolute right-2 top-2 w-10 h-10 border text-white`}
          onClick={handleStop}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.5893 8.45868L15.932 14.1147L10.276 8.45868L8.39062 10.344L14.0466 16L8.39062 21.656L10.276 23.5413L15.932 17.8853L21.5893 23.5413L23.4746 21.656L17.8186 16L23.4746 10.344L21.5893 8.45868Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {/* / fullscreen Video */}
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "contact", "index", "terms", "work"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    workImageHodnik: file(relativePath: { eq: "images/work_hodnik1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBecycle: file(relativePath: { eq: "images/work_becycle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevog: file(relativePath: { eq: "images/work_bevog_beers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageJoan: file(relativePath: { eq: "images/work_joan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBorstnikovo: file(relativePath: { eq: "images/work_borstnikovo.png" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogNoBg: file(relativePath: { eq: "images/work_bevog_beers_nobg.png" }) {
      childImageSharp {
        fluid(maxWidth: 860, maxHeight: 484, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogNoBgBg: file(relativePath: { eq: "images/work_bevog_beers_nobg_bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, maxHeight: 484, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageArtRebel: file(relativePath: { eq: "images/work_ArtRebel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogBuzz: file(relativePath: { eq: "images/work_Bevog_Buzz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogLola: file(relativePath: { eq: "images/work_Bevog_Lola.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogLola2: file(relativePath: { eq: "images/work_Bevog_Lola_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangImmersive3D: file(relativePath: { eq: "images/work_BigBang_Immersive3D.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangMega: file(relativePath: { eq: "images/work_BigBang_Mega.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangSC22: file(relativePath: { eq: "images/work_BigBang_SC22_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangUAUNSZ: file(relativePath: { eq: "images/work_BigBang_UAU_NSZ.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangUAUSP22: file(relativePath: { eq: "images/work_BigBang_UAU_SP22.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangUAU: file(relativePath: { eq: "images/work_BigBang_UAU.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageFab: file(relativePath: { eq: "images/work_Fab.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageFab2: file(relativePath: { eq: "images/work_Fab_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageGAAC: file(relativePath: { eq: "images/work_Google_Arts_And_Culture_clip.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageHRC: file(relativePath: { eq: "images/work_HRC.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMalner: file(relativePath: { eq: "images/work_Malner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMalner2: file(relativePath: { eq: "images/work_Malner_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMOL: file(relativePath: { eq: "images/work_MOL_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 539, maxHeight: 800, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageParsonsGin: file(relativePath: { eq: "images/work_ParsonsGIn.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 0) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSamsung: file(relativePath: { eq: "images/work_SAMSUNG.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSamsung2: file(relativePath: { eq: "images/work_Samsung_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSejem: file(relativePath: { eq: "images/work_Sejem_.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 560, maxHeight: 800, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZDL: file(relativePath: { eq: "images/work_ZDL.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZlataLisica: file(relativePath: { eq: "images/work_ZlataLisica_Ilustracija.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZMTDubai: file(relativePath: { eq: "images/work_ZMT_Dubai.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBright: file(relativePath: { eq: "images/work_bright.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageStereosun: file(relativePath: { eq: "images/work_stereosun.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageNLBLisica: file(relativePath: { eq: "images/work_ZlataLisica_Ilustracija.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageCargoX: file(relativePath: { eq: "images/work_CargoX.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageUrbana: file(relativePath: { eq: "images/work_Urbana_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZMTDubai2: file(relativePath: { eq: "images/work_ZMT_Dubai_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`;
